export const emoji =[
    "🥇",
    "🥈",
    "🏒",
    "🌁",
    "🌋",
    "🎄",
    "🎍",
    "😎",
    "🌉",
    "🌊",
]