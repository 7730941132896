import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./register.scss";
import axios from "axios";

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
    name: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
    name: "",
  });
  const [err, setErr] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    let validationErrors = {};

    // Check username
    if (!inputs.username.trim()) {
      validationErrors.username = "Please enter your username.";
    }

    // Check email
    if (!inputs.email.trim()) {
      validationErrors.email = "Please enter your email.";
    }

    // Check password
    if (!inputs.password.trim()) {
      validationErrors.password = "Please enter your password.";
    }

    // Check name
    if (!inputs.name.trim()) {
      validationErrors.name = "Please enter your name.";
    }

    // Set errors state with validation errors
    setErrors(validationErrors);

    // If there are any validation errors, return early
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      await axios.post("http://localhost:8800/api/auth/register", inputs);
      navigate("/login");
    } catch (err) {
      setErr(err.response.data);
    }
  };

  return (
    <div className="register">
      <div className="card">
        <div className="left">
          <h1>Charity App.</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero cum,
            alias totam numquam ipsa exercitationem dignissimos, error nam,
            consequatur.
          </p>
          <span>Do you have an account?</span>
          <Link to="/login">
            <button>Login</button>
          </Link>
        </div>
        <div className="right">
          <h1>Register</h1>
          <form>
            <input
              type="text"
              placeholder="Username"
              name="username"
              onChange={handleChange}
            />
            {formSubmitted && errors.username && <p className="text-danger">{errors.username}</p>}
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
            {formSubmitted && errors.email && <p className="text-danger">{errors.email}</p>}
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
            />
            {formSubmitted && errors.password && <p className="text-danger">{errors.password}</p>}
            <input
              type="text"
              placeholder="Name"
              name="name"
              onChange={handleChange}
            />
            {formSubmitted && errors.name && <p className="text-danger">{errors.name}</p>}
            {err && <p className="text-danger">{err}</p>}
            <button onClick={handleClick}>Register</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
