import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function NavBarLayout() {
  return (
    <>
      <Navbar bg="primary" data-bs-theme="dark" className='sticky-top'>
        <Container>
          <Navbar.Brand href="/">Navbar</Navbar.Brand>
          
        </Container>
          <Container>
              <InputGroup className="mb-3">
              <InputGroup.Text>Search Post</InputGroup.Text>
              <Form.Control aria-label="Dollar amount (with dot and two decimal places)" />
            </InputGroup>
          </Container>
      </Navbar>
    </>
  );
}

export default NavBarLayout;