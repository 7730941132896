import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import ConfettiExplosion from "react-confetti-explosion";

const UploadModal = ({ show, handleClose }) => {
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const queryClient = useQueryClient();

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await makeRequest.post("/upload", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post("/posts", newPost);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["posts"]);
        handleClose(); // Close the modal after successful post creation
      },
    }
  );

  const handleSubmit = async () => {
    // Check if both description and file are set
    if (!desc || !file) {
      alert("Please input a text and select an image");
      return; // Exit early if desc or file is not set
    }
  
    // Proceed with upload and mutation
    let imgUrl = "";
    if (file) imgUrl = await upload();
    mutation.mutate({ desc, img: imgUrl });
  
    // Clear input fields after submission
    setDesc("");
    setFile(null);
  };
  

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Thank you for the donation. 💕</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Donation Message 😄</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <Form.Control
              className="mt-3"
              type="file"
              placeholder="upload"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
        </Form>
        {/* Render ConfettiExplosion if showConfetti is true */}
        {show && <ConfettiExplosion />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModal;
