// Dashboard.js
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { makeRequest } from '../../axios';
import { isAdmin, isDonator } from '../../utils/roles';
import { FaTrashAlt } from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await makeRequest.get('/users/all-users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    setEditingUser(user);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingUser(null);
  };
  
  const handleDelete = (user) => {
    setEditingUser(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setEditingUser(null);
  };

  const handleUpdateUser = async () => {
    try {
      // Make the update request with the edited user data
      await makeRequest.put(`/users/update/${editingUser.id}`, editingUser); // Adjust the URL format here
      // Refresh the user list
      const response = await makeRequest.get('/users/all-users');
      setUsers(response.data);
      // Close the edit modal
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      // Make the delete request with the user ID
      await makeRequest.delete(`/users/delete/${editingUser.id}`);
      // Refresh the user list
      const response = await makeRequest.get('/users/all-users');
      setUsers(response.data);
      // Close the delete modal
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className='p-2'>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Role</th>
            <th>DonationStatus</th>
            <th>Location</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.username}</td>
              <td>{isAdmin(user) ? "Admin" : "User"}</td>
              <td>{user.donationStatus}</td>
              <td>{user.donationLocation}</td>
              <td>
                <Button variant="info" onClick={() => handleEdit(user)}><FaPenToSquare className='text-white'/></Button>
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(user)}> <FaTrashAlt/></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" value={editingUser?.name} onChange={(e) => setEditingUser({...editingUser, name: e.target.value})} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={editingUser?.email} onChange={(e) => setEditingUser({...editingUser, email: e.target.value})} />
            </Form.Group>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={editingUser?.username} onChange={(e) => setEditingUser({...editingUser, username: e.target.value})} />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={editingUser?.role} onChange={(e) => setEditingUser({...editingUser, role: e.target.value})}>
                <option value={0}>User</option>
                <option value={1}>Admin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formDonationStatus">
              <Form.Label>Donation Status</Form.Label>
              <Form.Control
                type="text"
                value={editingUser?.donationStatus || ''}
                onChange={(e) => setEditingUser({ ...editingUser, donationStatus: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                value={editingUser?.donationLocation || ''} 
                onChange={(e) => setEditingUser({ ...editingUser, donationLocation: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>Cancel</Button>
          <Button variant="primary" onClick={handleUpdateUser}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleConfirmDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleConfirmDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;
